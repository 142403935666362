import React from 'react';
import Avatar from '../../../components/Avatar';
import UserOne from '../../../assets/img/user5.png';

const CommonHeaderChat = () => {
	return (
		<>
			<div
				className='col d-flex align-items-center cursor-pointer'
				// onClick={() => setState(!state)}
				role='presentation'>
				<div className='me-3'>
					<div className='text-end'>
						<div className='fw-bold fs-6 mb-0'></div>
					</div>
				</div>
				<div className='position-relative'>
					<Avatar
						// srcSet={USERS.CHLOE.srcSet}
						src={UserOne}
						size={48}
						// color={USERS.CHLOE.color}
					/>
				</div>
			</div>
		</>
	);
};

export default CommonHeaderChat;
