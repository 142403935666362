import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import { useFormik } from 'formik';
import Spinner from '../../../components/bootstrap/Spinner';
import '../../../styles/Custom.css';
import LoginImg from '../../../assets/loginImage.jpg';
import LogoBlack from '../../../assets/NbysfBlack.png';
import LogoWhite from '../../../assets/nbysfc.png';
import { Userlogin } from '../../../redux/Slice';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { errTitle, scc, poscent, posTop, errIcon, sccIcon, BtnCanCel, BtnGreat } from '../Constant';
import { clearErrors, clearSuccesses, setLoadingStatus } from '../../../redux/Action';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../components/bootstrap/Modal';
import PropTypes from 'prop-types';

export const ModalPrivacy = ({ isOpen, setIsOpen }) => {
	return (
		<>
			<Modal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				size='xl'
				isScrollable={true}
				isCentered={true}
				isAnimation={true}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<ModalTitle>
						<h3>Privacy-Policy</h3>
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='container '>
						<div className='row mb-4'>
							<h4>PRIVACY NOTICE</h4>
							<h5>Last updated December 1, 2023</h5>
						</div>
						<div className='row mb-3'>
							<h6>
								Thank you for choosing to be part of our community at Buvana
								Enterprises, Inc., doing business as sigur solutions ("sigur
								solutions," "we," "us," or "our"). We are committed to protecting
								your personal information and your right to privacy. If you have any
								questions or concerns about this privacy notice or our practices
								with regard to your personal information, please contact us at
								sigursolutions@gmail.com.
							</h6>
						</div>
						<div className='row mb-3'>
							<h6>
								This privacy notice describes how we might use your information if
								you:
							</h6>
							<div className='col-lg-12 mb-2'>
								<ul>
									<li>Visit our website at https://sigursolutions.com/</li>
									<li>
										Engage with us in other related ways ― including any sales,
										marketing, or events
									</li>
									<h6>In this privacy notice, if we refer to:</h6>
									<li>
										{' '}
										<strong>"Website,"</strong> we are referring to any website
										of ours that references or links to this policy
									</li>
									<li>
										<strong>"Services,"</strong> we are referring to our
										Website, and other related services, including any sales,
										marketing, or events
									</li>
								</ul>
							</div>
							<div className='col-lg-12 mb-2'>
								<h6>
									The purpose of this privacy notice is to explain to you in the
									clearest way possible what information we collect, how we use
									it, and what rights you have in relation to it. If there are any
									terms in this privacy notice that you do not agree with, please
									discontinue use of our Services immediately.
								</h6>
							</div>
							<div className='col-lg-12 mb-2'>
								<h6>
									<strong>
										Please read this privacy notice carefully, as it will help
										you understand what we do with the information that we
										collect.
									</strong>
								</h6>
							</div>
						</div>
						<div className='row mb-3'>
							<div className='col-lg-12 mb-2'>
								<h2>TABLE OF CONTENTS</h2>
							</div>

							<div className='col-lg-12 mb-2'>
								<ol>
									<li>
										<a href='#text1' className='text-decoration-none'>
											WHAT INFORMATION DO WE COLLECT?
										</a>
									</li>
									<li>
										<a href='#text2' className='text-decoration-none'>
											HOW DO WE USE YOUR INFORMATION?
										</a>
									</li>
									<li>
										<a href='#text3' className='text-decoration-none'>
											WILL YOUR INFORMATION BE SHARED WITH ANYONE?
										</a>
									</li>
									<li>
										<a href='#text4' className='text-decoration-none'>
											DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
										</a>
									</li>
									<li>
										<a href='#text5' className='text-decoration-none'>
											HOW LONG DO WE KEEP YOUR INFORMATION?
										</a>
									</li>
									<li>
										<a href='#text6' className='text-decoration-none'>
											HOW DO WE KEEP YOUR INFORMATION SAFE?
										</a>
									</li>
									<li>
										<a href='#text7' className='text-decoration-none'>
											DO WE COLLECT INFORMATION FROM MINORS?
										</a>
									</li>
									<li>
										<a href='#text8' className='text-decoration-none'>
											WHAT ARE YOUR PRIVACY RIGHTS?
										</a>
									</li>
									<li>
										<a href='#text9' className='text-decoration-none'>
											CONTROLS FOR DO-NOT-TRACK FEATURES
										</a>
									</li>
									<li>
										<a href='#text10' className='text-decoration-none'>
											DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
										</a>
									</li>
									<li>
										<a href='#text11' className='text-decoration-none'>
											DO WE MAKE UPDATES TO THIS NOTICE?
										</a>
									</li>
									<li>
										<a href='#text12' className='text-decoration-none'>
											HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
										</a>
									</li>
									<li>
										<a href='#text13' className='text-decoration-none'>
											HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT
											FROM YOU?
										</a>
									</li>
								</ol>
							</div>
						</div>

						<div className='row mb-3' id='text1'>
							<div className='col-lg-12 mb-2'>
								<h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
							</div>
							<div className='col-lg-12 mb-2'>
								<h5>
									<strong>Personal information you disclose to us</strong>
								</h5>
							</div>
							<div className='col-lg-12 mb-2'>
								<h6>
									<strong>In Short:</strong> We collect personal information that
									you provide to us. We collect personal information that you
									voluntarily provide to us when you express an interest in
									obtaining information about us or our products and Services,
									when you participate in activities on the Website or otherwise
									when you contact us. The personal information that we collect
									depends on the context of your interactions with us and the
									Website, the choices you make and the products and features you
									use. The personal information we collect may include the
									following:
									<strong>Personal Information Provided by You. </strong>We
									collect names; phone numbers; email addresses; and other similar
									information. All personal information that you provide to us
									must be true, complete and accurate, and you must notify us of
									any changes to such personal information.
								</h6>
							</div>
						</div>

						<div className='row mb-3' id='text2'>
							<div className='col-lg-12 mb-2'>
								<h2>2. HOW DO WE USE YOUR INFORMATION?</h2>
							</div>

							<div className='col-lg-12 mb-2'>
								<h6>
									<strong>In Short:</strong> We process your information for
									purposes based on legitimate business interests, the fulfillment
									of our contract with you, compliance with our legal obligations,
									and/or your consent. We use personal information collected via
									our Website for a variety of business purposes described below.
									We process your personal information for these purposes in
									reliance on our legitimate business interests, in order to enter
									into or perform a contract with you, with your consent, and/or
									for compliance with our legal obligations. We indicate the
									specific processing grounds we rely on next to each purpose
									listed below.
								</h6>
							</div>
							<div className='col-lg-12 mb-2'>
								<h2>We use the information we collect or receive:</h2>
							</div>
							<div className='col-lg-12 mb-2'>
								<ul>
									<li>
										<strong>
											To facilitate account creation and logon process.
										</strong>{' '}
										If you choose to link your account with us to a third-party
										account (such as your Google or Facebook account), we use
										the information you allowed us to collect from those third
										parties to facilitate account creation and logon process for
										the performance of the contract.
									</li>
									<li>
										<strong>To post testimonials.</strong> We post testimonials
										on our Website that may contain personal information. Prior
										to posting a testimonial, we will obtain your consent to use
										your name and the content of the testimonial. If you wish to
										update, or delete your testimonial, please contact us at
										sigursolutions@gmail.com and be sure to include your name,
										testimonial location, and contact information.
									</li>
									<li>
										<strong>Request feedback.</strong> We may use your
										information to request feedback and to contact you about
										your use of our Website.
									</li>
									<li>
										<strong>To enable user-to-user communications. </strong> We
										may use your information in order to enable user-to-user
										communications with each user's consent.
									</li>
									<li>
										<strong>To manage user accounts. </strong> We may use your
										information for the purposes of managing our account and
										keeping it in working order.
									</li>
									<li>
										<strong>To send administrative information to you.</strong>{' '}
										We may use your personal information to send you product,
										service and new feature information and/or information about
										changes to our terms, conditions, and policies.
									</li>
									<li>
										<strong>To protect our Services. </strong> We may use your
										information as part of our efforts to keep our Website safe
										and secure (for example, for fraud monitoring and
										prevention).
									</li>
									<li>
										<strong>
											To enforce our terms, conditions and policies for
											business purposes, to comply with legal and regulatory
											requirements or in connection with our contract.
										</strong>
									</li>
									<li>
										<strong>
											To respond to legal requests and prevent harm.{' '}
										</strong>
										If we receive a subpoena or other legal request, we may need
										to inspect the data we hold to determine how to respond.
									</li>
									<li>
										<strong>Fulfill and manage your orders.</strong>We may use
										your information to fulfill and manage your orders,
										payments, returns, and exchanges made through the Website.
									</li>
									<li>
										<strong>Administer prize draws and competitions. </strong>We
										may use your information to administer prize draws and
										competitions when you elect to participate in our
										competitions.
									</li>
									<li>
										<strong>
											To deliver and facilitate delivery of services to the
											user.{' '}
										</strong>
										We may use your information to provide you with the
										requested service.
									</li>
									<li>
										<strong>
											To respond to user inquiries/offer support to users.{' '}
										</strong>
										We may use your information to respond to your inquiries and
										solve any potential issues you might have with the use of
										our Services.
									</li>
									<li>
										<strong>
											To send you marketing and promotional communications.{' '}
										</strong>
										We and/or our third-party marketing partners may use the
										personal information you send to us for our marketing
										purposes, if this is in accordance with your marketing
										preferences. For example, when expressing an interest in
										obtaining information about us or our Website, subscribing
										to marketing or otherwise contacting us, we will collect
										personal information from you. You can opt-out of our
										marketing emails at any time (see the "WHAT ARE YOUR PRIVACY
										RIGHTS?" below).
									</li>
									<li>
										<strong>Deliver targeted advertising to you. </strong>We may
										use your information to develop and display personalized
										content and advertising (and work with third parties who do
										so) tailored to your interests and/or location and to
										measure its effectiveness.
									</li>
								</ul>
							</div>
						</div>

						<div className='row mb-3' id='text3'>
							<div className='col-lg-12 mb-2'>
								<h2>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h2>
							</div>

							<div className='col-lg-12 mb-2'>
								<h6>
									<strong>In Short:</strong>We only share information with your
									consent, to comply with laws, to provide you with services, to
									protect your rights, or to fulfill business obligations.
								</h6>
							</div>

							<div className='col-lg-12 mb-2'>
								<h2>
									We may process or share your data that we hold based on the
									following legal basis:
								</h2>
							</div>

							<div className='col-lg-12 mb-2'>
								<ul>
									<li>
										<strong>Consent: </strong> We may process your data if you
										have given us specific consent to use your personal
										information for a specific purpose.
									</li>
									<li>
										<strong>Legitimate Interests: </strong> We may process your
										data when it is reasonably necessary to achieve our
										legitimate business interests.
									</li>
									<li>
										<strong>Performance of a Contract: </strong> Where we have
										entered into a contract with you, we may process your
										personal information to fulfill the terms of our contract.
									</li>
									<li>
										<strong>Legal Obligations:</strong> We may disclose your
										information where we are legally required to do so in order
										to comply with applicable law, governmental requests, a
										judicial proceeding, court order, or legal process, such as
										in response to a court order or a subpoena (including in
										response to public authorities to meet national security or
										law enforcement requirements).
									</li>
									<li>
										<strong>Vital Interests: </strong>
										We may disclose your information where we believe it is
										necessary to investigate, prevent, or take action regarding
										potential violations of our policies, suspected fraud,
										situations involving potential threats to the safety of any
										person and illegal activities, or as evidence in litigation
										in which we are involved.
										<h6>
											More specifically, we may need to process your data or
											share your personal information in the following
											situations:
										</h6>
									</li>
									<li>
										<strong>Business Transfers. </strong> We may share or
										transfer your information in connection with, or during
										negotiations of, any merger, sale of company assets,
										financing, or acquisition of all or a portion of our
										business to another company.
									</li>
								</ul>
							</div>
						</div>

						<div className='row mb-3' id='text4'>
							<div className='col-lg-12 mb-2'>
								<h2>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
							</div>

							<div className='col-lg-12 mb-2'>
								<h6>
									<strong>In Short:</strong>We may use cookies and other tracking
									technologies to collect and store your information.
								</h6>
							</div>

							<div className='col-lg-12 mb-2'>
								<h6>
									We may use cookies and similar tracking technologies (like web
									beacons and pixels) to access or store information. Specific
									information about how we use such technologies and how you can
									refuse certain cookies is set out in our Cookie Notice.
								</h6>
							</div>
						</div>

						<div className='row mb-3' id='text5'>
							<div className='col-lg-12 mb-2'>
								<h2>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
							</div>

							<div className='col-lg-12 mb-2'>
								<h6>
									<strong>In Short:</strong>We keep your information for as long
									as necessary to fulfill the purposes outlined in this privacy
									notice unless otherwise required by law.
								</h6>
							</div>

							<div className='col-lg-12 mb-2'>
								<h6>
									We will only keep your personal information for as long as it is
									necessary for the purposes set out in this privacy notice,
									unless a longer retention period is required or permitted by law
									(such as tax, accounting or other legal requirements). No
									purpose in this notice will require us keeping your personal
									information for longer than 90 days.
								</h6>
							</div>
							<div className='col-lg-12 mb-2'>
								<h6>
									When we have no ongoing legitimate business need to process your
									personal information, we will either delete or anonymize such
									information, or, if this is not possible (for example, because
									your personal information has been stored in backup archives),
									then we will securely store your personal information and
									isolate it from any further processing until deletion is
									possible.
								</h6>
							</div>
						</div>

						<div className='row mb-3' id='text6'>
							<div className='col-lg-12 mb-2'>
								<h2>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
							</div>

							<div className='col-lg-12 mb-2'>
								<h6>
									<strong>In Short:</strong>We aim to protect your personal
									information through a system of organizational and technical
									security measures.
								</h6>
							</div>

							<div className='col-lg-12 mb-2'>
								<h6>
									We have implemented appropriate technical and organizational
									security measures designed to protect the security of any
									personal information we process. However, despite our safeguards
									and efforts to secure your information, no electronic
									transmission over the Internet or information storage technology
									can be guaranteed to be 100% secure, so we cannot promise or
									guarantee that hackers, cybercriminals, or other unauthorized
									third parties will not be able to defeat our security, and
									improperly collect, access, steal, or modify your information.
									Although we will do our best to protect your personal
									information, transmission of personal information to and from
									our Website is at your own risk. You should only access the
									Website within a secure environment.
								</h6>
							</div>
						</div>

						<div className='row mb-3' id='text7'>
							<div className='col-lg-12 mb-2'>
								<h2>7. DO WE COLLECT INFORMATION FROM MINORS?</h2>
							</div>

							<div className='col-lg-12 mb-2'>
								<h6>
									<strong>In Short:</strong>We do not knowingly collect data from
									or market to children under 18 years of age.
								</h6>
							</div>

							<div className='col-lg-12 mb-2'>
								<h6>
									We do not knowingly solicit data from or market to children
									under 18 years of age. By using the Website, you represent that
									you are at least 18 or that you are the parent or guardian of
									such a minor and consent to such minor dependent’s use of the
									Website. If we learn that personal information from users less
									than 18 years of age has been collected, we will deactivate the
									account and take reasonable measures to promptly delete such
									data from our records. If you become aware of any data we may
									have collected from children under age 18, please contact us at
									sigursolutions@gmail.com.
								</h6>
							</div>
						</div>

						<div className='row mb-3' id='text8'>
							<div className='col-lg-12 mb-2'>
								<h2>8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
							</div>

							<div className='col-lg-12 mb-2'>
								<h6>
									<strong>In Short:</strong>You may review, change, or terminate
									your account at any time.
								</h6>
							</div>

							<div className='col-lg-12 mb-2'>
								<h6>
									If you are a resident in the EEA or UK and you believe we are
									unlawfully processing your personal information, you also have
									the right to complain to your local data protection supervisory
									authority. You can find their contact details here:{' '}
									<a
										href=' https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.'
										target='_blank'>
										{' '}
										https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
									</a>
								</h6>
							</div>
							<div className='col-lg-12 mb-2'>
								<h6>
									If you are a resident in Switzerland, the contact details for
									the data protection authorities are available here:{' '}
									<a
										href='https://www.edoeb.admin.ch/edoeb/en/home.html.'
										target='_blank'
										rel='noreferrer'>
										{' '}
										https://www.edoeb.admin.ch/edoeb/en/home.html.
									</a>
								</h6>
							</div>
							<div className='col-lg-12 mb-2'>
								<h6>
									<strong>
										{' '}
										<u>Cookies and similar technologies: </u>
									</strong>
									Most Web browsers are set to accept cookies by default. If you
									prefer, you can usually choose to set your browser to remove
									cookies and to reject cookies. If you choose to remove cookies
									or reject cookies, this could affect certain features or
									services of our Website. To opt-out of interest-based
									advertising by advertisers on our Website visit{' '}
									<a
										href='http://www.aboutads.info/choices/'
										target='_blank'
										rel='noreferrer'>
										http://www.aboutads.info/choices/
									</a>
								</h6>
							</div>
						</div>

						<div className='row mb-3' id='text9'>
							<div className='col-lg-12 mb-2'>
								<h2>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
							</div>

							<div className='col-lg-12 mb-2'>
								<h6>
									Most web browsers and some mobile operating systems and mobile
									applications include a Do-Not-Track ("DNT") feature or setting
									you can activate to signal your privacy preference not to have
									data about your online browsing activities monitored and
									collected. At this stage no uniform technology standard for
									recognizing and implementing DNT signals has been finalized. As
									such, we do not currently respond to DNT browser signals or any
									other mechanism that automatically communicates your choice not
									to be tracked online. If a standard for online tracking is
									adopted that we must follow in the future, we will inform you
									about that practice in a revised version of this privacy notice.
								</h6>
							</div>
						</div>

						<div className='row mb-3' id='text10'>
							<div className='col-lg-12 mb-2'>
								<h2>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
							</div>

							<div className='col-lg-12 mb-2'>
								<h6>
									<strong>In Short:</strong>Yes, if you are a resident of
									California, you are granted specific rights regarding access to
									your personal information.
								</h6>
							</div>
							<div className='col-lg-12 mb-2'>
								<h6>
									California Civil Code Section 1798.83, also known as the "Shine
									The Light" law, permits our users who are California residents
									to request and obtain from us, once a year and free of charge,
									information about categories of personal information (if any) we
									disclosed to third parties for direct marketing purposes and the
									names and addresses of all third parties with which we shared
									personal information in the immediately preceding calendar year.
									If you are a California resident and would like to make such a
									request, please submit your request in writing to us using the
									contact information provided below.
								</h6>
							</div>
							<div className='col-lg-12 mb-2'>
								<h6>
									If you are under 18 years of age, reside in California, and have
									a registered account with the Website, you have the right to
									request removal of unwanted data that you publicly post on the
									Website. To request removal of such data, please contact us
									using the contact information provided below, and include the
									email address associated with your account and a statement that
									you reside in California. We will make sure the data is not
									publicly displayed on the Website, but please be aware that the
									data may not be completely or comprehensively removed from all
									our systems (e.g. backups, etc.).
								</h6>
							</div>
						</div>

						<div className='row mb-3' id='text11'>
							<div className='col-lg-12 mb-2'>
								<h2>11. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
							</div>

							<div className='col-lg-12 mb-2'>
								<h6>
									<strong>In Short:</strong>Yes, we will update this notice as
									necessary to stay compliant with relevant laws.
								</h6>
							</div>
							<div className='col-lg-12 mb-2'>
								<h6>
									We may update this privacy notice from time to time. The updated
									version will be indicated by an updated "Revised" date and the
									updated version will be effective as soon as it is accessible.
									If we make material changes to this privacy notice, we may
									notify you either by prominently posting a notice of such
									changes or by directly sending you a notification. We encourage
									you to review this privacy notice frequently to be informed of
									how we are protecting your information.
								</h6>
							</div>
						</div>

						<div className='row mb-3' id='text12'>
							<div className='col-lg-12 mb-2'>
								<h2>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
							</div>

							<div className='col-lg-12 mb-2'>
								<h6>
									If you have questions or comments about this notice, you may
									email us at sigursolutions@gmail.com or by post to:
								</h6>
							</div>
							<div className='col-lg-12 mb-2'>
								<h6>Buvana Enterprises, Inc.</h6>
								<h6>1040 Orchard Street</h6>
								<h6>Suite D</h6>
								<h6>North Brunswick, NJ 08902</h6>
								<h6>United States</h6>
							</div>
						</div>

						<div className='row mb-3' id='text13'>
							<div className='col-lg-12 mb-2'>
								<h2>
									13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
									FROM YOU?
								</h2>
							</div>

							<div className='col-lg-12 mb-2'>
								<h6>
									Based on the applicable laws of your country, you may have the
									right to request access to the personal information we collect
									from you, change that information, or delete it in some
									circumstances. To request to review, update, or delete your
									personal information, please visit: sigursolutions@gmail.com.
								</h6>
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};

ModalPrivacy.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
};

const Login = () => {
	const { error, Loading, success, login } = useSelector((state) => state.festiv);
	const [isLoading, setIsLoading] = useState(false);
	const [modalShow, setModalShow] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		if (login) {
			navigate('/');
		}
	}, [login, navigate]);

	const Notification = useCallback((val, tit, pos, ico, btn) => {
		Swal.fire({
			position: `${pos}`,
			title: `${tit}`,
			text: `${val}`,
			icon: `${ico}`,
			confirmButtonText: `${btn}`,
		});
		setIsLoading(false);
		clearErrors();
		clearSuccesses();
		setLoadingStatus(false);
	}, []);

	useEffect(() => {
		if (error) {
			Notification(error, errTitle, poscent, errIcon, BtnCanCel);
		}
		if (success) {
			Notification(success, scc, posTop, sccIcon, BtnGreat);
		}
		if (Loading) {
			setIsLoading(true);
		} else {
			setIsLoading(false);
		}
	}, [error, success, Loading, Notification]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			username: '',
			password: '',
		},
		validate: (values) => {
			const errors = {};
			if (!values.username) {
				errors.username = 'Required';
			} else if (values.username.length < 3) {
				errors.username = 'Must be 3 characters or more';
			} else if (values.username.length > 20) {
				errors.username = 'Must be 20 characters or less';
			}

			if (!values.password) {
				errors.password = 'Required';
			} else if (!/[0-9]/g.test(values.password)) {
				errors.password = 'Require that at least one digit appear anywhere in the string. ';
			} else if (!/[a-z]/g.test(values.password)) {
				errors.password =
					'Require that at least one lowercase letter appear anywhere in the string. ';
			}

			if (Object.keys(errors).length === 0) {
				formik.setStatus({ isSubmitting: true });
			}
			return errors;
		},
		validateOnChange: true,
		onSubmit: async (values, { setSubmitting }) => {
			dispatch(Userlogin(values));
			setIsLoading(true);
			setTimeout(() => {
				setSubmitting(false);
			}, 2000);
		},
	});

	return (
		<div className='container-fluid '>
			<div className='row LoginRow'>
				<div className='col-lg-6 LoginCol'>
					<img src={LoginImg} alt='Login' />
				</div>
				<div className='col-lg-6 LoginCol2'>
					<div className='LoginSmallbg'>
						<div className='row LoginImage'>
							<div className='text-center'>
								<img src={LogoWhite} className='LogoWhite' alt='LogoWhite' />
								<img src={LogoBlack} className='LogoBlack' alt='LogoWhite' />
							</div>
							<form
								onSubmit={formik.handleSubmit}
								className='row g-2 justify-content-center'>
								<div className='col-8'>
									<FormGroup id='username' label='Username'>
										<Input
											type='text'
											placeholder='Enter Your Username'
											autoComplete='text'
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.username}
											isValid={formik.isValid}
											isTouched={formik.touched.username}
											invalidFeedback={formik.errors.username}
											validFeedback='Looks good!'
										/>
									</FormGroup>
									<FormGroup id='password' label='Password'>
										<Input
											type='password'
											value={formik.values.password}
											isTouched={formik.touched.password}
											invalidFeedback={formik.errors.password}
											validFeedback='Looks good!'
											isValid={formik.isValid}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											placeholder='Enter Your Password'
										/>
									</FormGroup>
									<div className='col-12 d-flex justify-content-center'>
										<Button
											className='w-50 py-3 my-3 fs-5'
											color='warning'
											onClick={formik.handleSubmit}>
											{isLoading && <Spinner isSmall inButton />}
											Login
										</Button>
									</div>
								</div>
							</form>

							<div
								className={classNames('w-100 cursor-pointer text-center')}
								onClick={() => {
									setModalShow(true);
								}}>
								<span className='d-flex w-100 justify-content-center text-info'>
									<p className={classNames('text-decoration-none me-3')}>
										Privacy policy
									</p>
									<p>Terms of use</p>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='container'>
				{<ModalPrivacy setIsOpen={setModalShow} isOpen={modalShow} />}
			</div>
		</div>
	);
};

export default Login;
